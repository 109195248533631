.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.mr-auto, .mx-auto {
    margin-right: auto !important;
}

.d-none {
    display: none;
}