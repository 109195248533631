.App {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    background: #E9F0F5;
}

.MainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 1px;
}

.Body {
    background: #fff;
    overflow: hidden;
    display: flex;
    flex: 1;
    margin: 10px 10px 0 10px;
    overflow-y: auto;
}

.Header {
    background: #fff;
    padding: 8px 0;
    /*flex-basis: 53px;*/
    z-index: 202;
    display: flex;
}

.HeaderContent {
    margin:auto;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.HeaderContent > .dropdown.btn-group {
    margin: auto 0;
}

.pagebreak {
    page-break-before: always;
    page-break-inside: avoid;
    /* page-break-after works, as well */
}

.clearAllFilters {
    margin: auto 10px auto 10px;
    color:#4DC6D1;
    cursor:pointer;
    text-decoration: underline;
    white-space: nowrap;
}

.dimensionLabelCheck {
    float: right;
    color: transparent;
}

.dimensionLabelCheck.active {
    color: #1b6d85;
}

.topStateHeaderDimension {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    min-width: 250px;
}

.topStateHeaderDimension > * {
    margin: auto;
}

.headerFilterLabel {
    margin: auto;
    font-size: 10px;
    color: #8CA0B3;
    font-weight: 700;
    text-transform: uppercase;
}

.headerFilterSelect {
    width: 100%;
}

.Sidebar {
    background: #404A59;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 180px;
    flex-shrink: 0;
    min-width: 1px;
    max-width: 260px;
    z-index: 201;
    box-sizing: content-box;
    visibility: visible;
    height: 100%;
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 15px;
    color: #fff;
}

.SidebarContent {
    overflow: hidden;
    flex-direction: column;
    display: flex;
    flex: 1;
    height: 100vh; /* shouldn't be necessary, but CSS and I are not friend... */
}

/*
    ---- HEADER
 */

.SidebarHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-size: 11px;
}

.SidebarHeaderLeft > span {
    display: block;
}


.SidebarHeaderWelcome {
    font-style: italic;
}

.SidebarHeaderUserName {
    text-transform: uppercase;
}

.SidebarHeaderLogout {
    cursor: pointer;
    font-size: 10px;
    text-decoration-line: underline;
}

/*
    ---- NAVIGATION
 */

.SidebarNavigation {
    text-transform: uppercase;
    background: rgba(255,255,255,.06);
}

.SidebarNavigation, .SidebarNavGroup {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.SidebarNavItem, .SidebarNavGroupItem {
    font-size: 15px;
    line-height: 15px;
    padding: 10px 15px;
    height: 40px;
    border: none;
    outline: 0;
    border-bottom: 1px solid #404a59;
    opacity: .84;
}

.SidebarNavGroupItemText {
    line-height: 18px;
    margin-left: 15px;
}

.SidebarNavItemText {
    line-height: 20px;
    margin-left: 5px;
    font-size: 12px;
}

.SidebarNavGroup {
    margin-left: 20px;
    border-left: 2px solid rgba(255,255,255,.5);
}

.SidebarNavItem {
    position: relative;
    cursor: pointer;
}

.SidebarNavigationHeader {
    font-weight: 700;
    color: #d5d7da;
    padding: 15px 20px;
    letter-spacing: 0.5px;
}

.SidebarNavGroup > .SidebarNavItem {
    padding-left: 20px;
}

.SidebarNavGroup > .SidebarNavItem:before {
    left: 0;
    top: 20px;
    width: 15px;
    content: ' ';
    position: absolute;
    display: inline-block;
    border: 1px solid rgba(255,255,255,.5);
}

.open {
    background: rgba(0,0,0,.2);
}

.SidebarNavGroupItemArrow {
    line-height: 20px;
    position: absolute;
    right: 15px;
}

.SidebarIcon.open {
    padding-top: 23px;
}

.SidebarNavItem:hover {
    background: rgba(0,0,0,.2);
}

.SidebarNavItem.selected {
    background: rgba(0,0,0,.2);
    border-left: 5px solid #4dc6d1;
    padding-left: 15px;
}

.ant-select-dropdown-menu-item-group-title {
    color: #1C1E56;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-top: 10px;
}

.ant-select-dropdown-menu-item {
    color: #213848;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

.bottom-drawer-tooltip {
    z-index: 9999;
}

.ant-select-selection-selected-value .translated-meta {
    display: none;
}

.ant-select-dropdown-menu-item-selected .selected-language-tick {
    visibility: visible;
}

.translated-meta-horizontal {
    visibility: hidden;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6;
}

.language-selector .ant-select-selection-selected-value .translated-meta-horizontal {
    visibility: visible;
}

.relative-pos {
    position: relative;
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: 4px!important;
    padding-left: 4px!important
}

.px-2 {
    padding-right: 8px!important;
    padding-left: 8px!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: 4px!important;
    padding-bottom: 4px!important
}

.py-2 {
    padding-top: 8px!important;
    padding-bottom: 8px!important
}

.my-1 {
    margin-top: 4px!important;
    margin-bottom: 4px!important
}

.my-2 {
    margin-top: 8px!important;
    margin-bottom: 8px!important
}

.text-center {
    text-align: center !important;
}

.display-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.no-pointers {
    pointer-events: none !important;
}


