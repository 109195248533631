@keyframes emptyStackMessageAnimation {
    to   { visibility: visible; }
  }

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) rotate(-15deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}