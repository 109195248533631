.chart-svg {
  width: 100%;
  height: 100%;
}

.bubble-graph {
  opacity: 0;
  animation-name: animateIn;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

svg.word-cloud text {
	animation: stroke 0.25s ease-in-out alternate;
}