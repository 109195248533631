.well {
  padding: 14px 14px 14px 17px;
  border: 1px solid #e4ecf0;
  border-radius: 4px;
  background-color: #f9fafb;
  box-sizing: border-box;
  font-size: 14px;
}

.introductionT,
.questionT,
.categoryT,
.questionTypeT,
.channelT {
  padding: 16px;
  background-color: #f4f6f9;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 0.3em;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
