@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

html, body {
    min-height: 100%;
    height: auto;
}

body {
    margin: 0;
    padding: 0;

    background: #fff;

    overflow-y: auto;
    overflow-x: hidden;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 0;
}

.ant-switch-checked {
  background-color: #00c3d9 !important;
}

@media print {
    .block--print-hide.block--print-hide {
        display: none;
        width: 0px;
        min-width: 0px;
        max-width: 0px;
        height: 0px;
        min-height: 0px;
        max-height: 0px;
    }

    @page {
        margin: 20px;
    }
  
    .block--print-show.block--print-show {
        display: block;

        overflow: visible;
        width: 100%;
        padding: 10px;
        zoom: 70%;
    }

    #navigationContainer.block--print-show {
        position: absolute;
    }
  
    .ant-tabs-nav-wrap.ant-tabs-nav-wrap {
        visibility: hidden;
        width: 0px;
        min-width: 0px;
        max-width: 0px;
        height: 0px;
        min-height: 0px;
        max-height: 0px;
    }
  
    .chart--print-hide {
        display: none
    }
  
    .chart-details--print-show {
        display: flex;
    }

  }
  
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
}
