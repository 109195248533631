.ml-1 {
    margin-left: .25rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-24p {
    margin-bottom: 24px;
}

.mt-24p {
    margin-top: 24px;
}

.mb-16p {
    margin-bottom: 16px;
}

.mt-16p {
    margin-top: 16px;
}